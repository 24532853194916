export const RBZEMAIL = "@rbz.co.zw";

// const currentHost = `${window.location.protocol}//${window.location.hostname}`;

// export const APIURL = `${currentHost}/uatdmsapi/`;

// export const ImageAPI = `${currentHost}/uatdmsfs/`;


// ---------------------------------------------------------------------

// get n app

// export const APIURL = "https://dmsupgrade.in/API/";

// export const ImageAPI = "https://docs.dmsupgrade.in/StagingAPI/";

// ---------------------------------------------------------------------

// export const APIURL = "https://uatapi.dms.rbz.co.zw/";

// export const ImageAPI="https://uatfs.dms.rbz.co.zw/api/";

// ---------------------------------------------------------------------

// New Server 2

// export const APIURL = "https://uatapidms.rbz.co.zw/";

// export const ImageAPI="https://uatfsdms.rbz.co.zw/api/";

// --------------------------------------------------------

// New Updated API url

// export const APIURL = "https://dms.rbz.co.zw:4443/uatdmsapi/";

// export const ImageAPI = "https://dms.rbz.co.zw:4443/uatdmsfs/";

// -----------------------------

// API url---- for dmsupgrade.in website

// export const ImageAPI = "https://docs.dmsupgrade.in/API/";

export const APIURL = "https://dev.dmsupgrade.in/API/";

export const ImageAPI = "https://dev.dmsupgrade.in/FilesAPI/"


export const ViewImageAPI = "https://dev.dmsupgrade.in/Files/"

